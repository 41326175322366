import * as React from "react";
import {useState, useEffect, useCallback} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,
    Stack,
    Drawer,
    IconButton, Button,
    IconButtonProps,
    Typography, Grid, Paper, Link, FilledInput, Dialog, DialogContent,DialogTitle,DialogContentText,DialogActions,
    Tooltip,
    Divider,
    List, Pagination, FormControl, InputLabel, OutlinedInput, InputAdornment,
    MenuItem, Select, Alert
} from '@mui/material';

import  { SelectChangeEvent } from '@mui/material/Select';

import Logo from 'src/components/logo';
// hooks
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {Helmet} from "react-helmet-async";
import {useForm} from "react-hook-form";
import {LoadingButton} from "@mui/lab";
import ReactFlagsSelect from "react-flags-select";
import useResponsive from '../../../hooks/useResponsive';
import './Cadastro.css';
import bannerRS from '../img/img-banner-cadastro-rs.jpg';

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';

import FormProvider, { RHFTextField, RHFSelect } from '../../../components/hook-form';
import {useAuthContext} from "../../../auth/useAuthContext";
import RHFnascimento from "../../../components/hook-form/RHFnascimento";
import RHFtelefone from "../../../components/hook-form/RHFtelefone";
import {RHFSelectBandeiras} from "../../../components/hook-form/RHFSelectBandeiras";
import ValidarWhatsapp from './componentesCadastro/ValidarWhatsapp';
import {criarevento, criarurl} from "../../../utils/analytics ";
// eslint-disable-next-line import/order
import {useGoogleLogin} from "@react-oauth/google";
import {useSnackbar} from "../../../components/snackbar";








//

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
// ----------------------------------------------------------------------




type FormValuesProps = {
    email: string;
    password: string;
    afterSubmit?: string;
};




export default function Cadastro() {
    const navigate = useNavigate();
    const [cadastroEmail, setCadastroEmail] = useState(false);
    const [UrlGoogle, setUrlGoogle] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const { register ,loginGoogle} = useAuthContext();
    const {tipoAtendente = ''} = useParams();
    const [openDialog, setOpenDialog] = useState(false);
    const [valora, setvalora] = useState(15.9);
    const { enqueueSnackbar } = useSnackbar();
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const isDesktop = useResponsive('up', 'lg');

    const getUrlGoogle= useCallback(async () => {
        criarevento('cadastro_iniciado');
        try {
            const response = await axios.get('/google/auth');

            const {url} = response.data;

            window.location.replace(url)

        } catch (error) {
            console.error(error);
        }
    }, []);






    useEffect(() => {
            localStorage.setItem('tipoAtendentecadastro',tipoAtendente);


    }, [tipoAtendente]);
    
    



    const irCadastroEmail = () => {
        criarevento('cadastro_iniciado');
        setCadastroEmail(true);
    }

    const voltarCadastroEmail = () => {
        setCadastroEmail(false);
    }

    const [pais, setPais] = useState('');

    const handleChangePais = (event: SelectChangeEvent) => {
        setPais(event.target.value as string);
    };

    const [selected, setSelected] = useState("");
    const phoneRegExp = /^\([1-9]{2}\) (?:[2-8]|9[0-9])[0-9]{3}-[0-9]{4}$/

    const getFormatedDate = (currentDate:any) => currentDate.split('/').reverse().join('-')

    const date:any = new Date();

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required('Este campo é obrigatorio.').email('Por favor, digite um email valido'),
        password: Yup.string().required('Senha é obrigatoria'),
        nome: Yup.string().required('Nome é obrigatorio'),
        sobrenome: Yup.string().required('Sobre Nome é obrigatorio'),
        CodigoPais: Yup.string().required('Pais é obrigatorio'),
        DataNascimento: Yup.date().max(`${date.getFullYear() - 18}-${ date.getMonth() +1    }-${ date.getDate() }` , 'Proibido para menores de 18 anos.').required('Nascimento é obrigatorio'),
        ondeEcontrou: Yup.string().required('Onde encontrou é obrigatorio'),
        NumeroWhatsApp: Yup.string().required('WhatsApp é obrigatorio').matches(phoneRegExp, 'Por favor digite um numero de telefone válido.'),
    });


    const [ondeEncontrou, setOndeEncontrou] = useState('');
    const [conversion, setconversion] = useState(false);






    const handleChangeOndeEncontrou = (event: SelectChangeEvent) => {
        setOndeEncontrou(event.target.value as string);
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema)
    });
    const {
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
        getValues
    } = methods;


    console.log(errors);
    const onSubmit = async (data: FormValuesProps) => {
        try {

         const dadoscerto =  Object.assign(data, {tipoAtendente});



            if (register) {
                await register(dadoscerto);
            }

          handleOpenDialog();

            criarevento('concluir_cadastro');

            setconversion(true);




        } catch (error) {
            console.error(error);

            setError('afterSubmit', {
                ...error,
                message: error.message || error,
            });
        }
    };

    const [open, setOpen] = useState(true);

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };



    useEffect(() => {
                        if(localStorage.getItem('accessToken')){
                            navigate(PATH_DASHBOARD.dashfirst);
                        }
    }, [navigate]);



    useEffect(() => {
        setTimeout(()=>{

        },3000);

    }, []);



    const conversionchange = () => {
        setconversion(true);
    };

    const testarurlgtag = () => {
        criarurl('teste-url');
    };

    const logins = useGoogleLogin({
        onSuccess:(tokenResponse)  => {


            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then(async (res) =>  {
                    console.log(res.data);
                    if (loginGoogle) {
                        loginGoogle(res.data);
                    }
                    navigate(PATH_DASHBOARD.dashfirst);


                })
                .catch((err) => console.log(err));

        },onError:()=>{
            enqueueSnackbar(`Erro ao logar tente de outra forma`, { variant: 'error' });
        }
    });


    return (
        <>
            {cadastroEmail === false ? (
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className='MainCadastroButtons' sx={{height: '100%'}}>
                
                {isDesktop ? (
                    <Grid item xs={12} md={8} >
                    <Item className='heroCadasterBackground'> <></> </Item>
                </Grid>
                ) : ('') }
                

                <Grid item xs={12} md={4}>
                        <Grid className='ItensMainCadastro'>
                            <Grid item xs={12}>
                                <Item className='TopoCadastre'>
                                    <Button onClick={()=>{ navigate(PATH_AUTH.login)}}>
                                        <Logo/>
                                    </Button>
                                    <Typography className='TituloCadastre'>Que bom que você está aqui</Typography>
                                    <Typography>  Faça seu cadastro para ser atendido(a) por um anjo ou profissional Eyhe. </Typography>
                                </Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Item>
                                    <Button className='EmailCadastroButton' onClick={irCadastroEmail} fullWidth> <Iconify icon="eva:email-outline" /> CRIAR CONTA COM E-MAIL </Button>
                                </Item>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Item>
                                    <Button onClick={() => logins()} className='GoogleCadastroButton' fullWidth > <Iconify icon="eva:google-fill" /> CRIAR CONTA COM GOOGLE </Button>
                                </Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Item>
                                    <Button className='FazerLoginButton' onClick={()=>{ navigate(PATH_AUTH.login)}}> <Iconify icon="eva:log-in-fill" /> Já tenho conta </Button>
                                </Item>
                            </Grid>

                            <Grid item md={8} xs={10}>
                                <Item>
                                    <Link href="https://heroi.eyhe.com.br/#/cadastrors">
                                        <img src={bannerRS} style={{width: '100%', borderRadius: '8px', marginTop: '40px'}} alt="Banner para Cadastro Rio Grande do Sul"/>
                                    </Link>
                                </Item>
                            </Grid>
                            
                        </Grid>
                </Grid>
            </Grid>
            ) : (
                <div className='MainCadastroGoogle'>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        {!!errors.afterSubmit && 

                        <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle id="alert-dialog-title">
                        <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        >
                            <Iconify className='NotificaErrorIcon' icon='eva:alert-triangle-outline' color='error' sx={{mr: 1}} />
                            <Typography color='error' variant="h6" > Erro ao criar conta </Typography>
                            
                        </Grid>
                        
                        </DialogTitle>
                        <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            
                            {errors.afterSubmit.message}
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} variant='contained' autoFocus>
                            Fechar
                        </Button>
                        </DialogActions>
                        </Dialog>

                        }
                <Grid container className='ItensMainCadastroGoogle'>
                    <Grid item xs={12}>
                        <Item className='TopoCadastre'>
                                <Button variant="text" onClick={voltarCadastroEmail} >
                                    <Logo/>
                                </Button >
                            <Typography className='TituloCadastre'>Preencha os dados abaixo para se cadastrar como Herói e conversar com nossos atendentes.</Typography>
                            <Typography variant='subtitle1' sx={{mt: 1.5}}>Para se cadastrar como Anjo <a style={{color: '#078DEE', textDecoration: 'none'}} rel="noreferrer" target='_blank' href='https://www.eyhe.com.br/5/lp-anjo.html'>clique aqui</a>, para se cadastrar como atendente profissional <a style={{color: '#078DEE', textDecoration: 'none'}} rel="noreferrer" target='_blank' href='https://www.eyhe.com.br/5/lp-cadastro-profissionais.html'>clique aqui</a>. Caso precise de ajuda escreva para <a style={{color: '#078DEE', textDecoration: 'none'}} href='mailto:contato@eyhe.com.br'>contato@eyhe.com.br</a> </Typography>



                        </Item>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFTextField name="nome" id="filled-adornment-amount"  label="Nome" />
                            </FormControl>
                        </Item>

                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFTextField name="sobrenome" id="filled-adornment-amount" label="Sobrenome"  />
                            </FormControl>
                        </Item>

                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">

                           
                            <RHFSelectBandeiras name="CodigoPais" />


                                
                            </FormControl>
                        </Item>

                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFtelefone name="NumeroWhatsApp"  label="Whatsapp"  />
                            </FormControl>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFTextField name="email" id="filled-adornment-amount" label="E-mail"  />
                            </FormControl>
                        </Item>

                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">

                                <RHFSelect native name="ondeEcontrou"  label="Onde Encontrou"  >
                                                <option value="" />
                                                <option  value='Facebook'> Facebook</option>
                                                <option  value='Instagram'> Instagram</option>
                                                <option  value='Google'> Google</option>
                                                <option  value='Publicidade'> Publicidade</option>


                                </RHFSelect>

                            </FormControl>
                        </Item>

                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFnascimento name="DataNascimento" id="filled-adornment-amount" label="Data de Nascimento"   />
                            </FormControl>
                        </Item>

                        <Item>
                            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                                <RHFTextField
                                    name="password"
                                    label="Senha"
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Item>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Item className='BotaoCadastreSe'>
                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitSuccessful || isSubmitting}
                            >
                                Cadastre-se
                            </LoadingButton>

                            
                        </Item>
                        <Item>
                            <Button fullWidth onClick={voltarCadastroEmail} variant='outlined' sx={{mt: 0.5}} size="large">
                                    Voltar
                            </Button>
                        </Item>

                        <Item>
                        {/* <Button variant="outlined" onClick={handleOpenDialog}> */}
                        {/*    Abrir Diálogo */}
                        {/* </Button> */}
                        <ValidarWhatsapp open={openDialog} dados={getValues} handleClose={handleCloseDialog} />
                        </Item>
                    </Grid>

                </Grid>

               </FormProvider>
            </div>
            )}

              {conversion ?(<Helmet>
                <script>{`gtag('event', 'conversion', {'send_to': 'AW-696299330/jJn-CNr86pIZEMLegswC'});`}</script>
              </Helmet>): ('')}




        </>
    )
}
