import { Helmet } from 'react-helmet-async';
import {useCallback, useEffect, useState} from 'react';
import sumBy from 'lodash/sumBy';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {styled, useTheme} from '@mui/material/styles';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  Box,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FilledInput,
  MenuItem,
  InputLabel,
  Select,
  InputAdornment,
  Paper, TextField, Typography,
} from '@mui/material';
// routes
import {useSnackbar} from "notistack";
import {SelectChangeEvent} from "@mui/material/Select";
import * as React from "react";
import axios from "../../../utils/axios";
import { PATH_DASHBOARD } from '../../../routes/paths';
// utils
import { fTimestamp } from '../../../utils/formatTime';
// _mock_
import { _invoices } from '../../../_mock/arrays';
// @types
import { IInvoice } from '../../../@types/invoice';
// components
import Label from '../../../components/label';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../components/table';
// sections
import InvoiceAnalytic from './invoice/InvoiceAnalytic';
import { InvoiceTableRow, InvoiceTableToolbar } from './invoice/list';
import {useAuthContext} from "../../../auth/useAuthContext";

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  'all',
  'full stack development',
  'backend development',
  'ui design',
  'ui/ux design',
  'front end development',
];

const TABLE_HEAD = [
  { id: 'invoiceNumber', label: 'Tipo Transação ', align: 'left' },
  { id: 'createDate', label: 'Data Transação', align: 'left' },
  { id: 'dueDate', label: 'Data Conclusão', align: 'left' },
  { id: 'price', label: 'Valor', align: 'center', width: 140 },
  { id: 'status', label: 'Status', align: 'left' },
];


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

export default function InvoiceListPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'id' });


  console.log(page);



  const [tableData, setTableData] = useState(_invoices);

  const [filterName, setFilterName] = useState('');

  const [openConfirm, setOpenConfirm] = useState(false);

  const [filterStatus, setFilterStatus] = useState('all');

  const [filterService, setFilterService] = useState('all');

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);



  const { enqueueSnackbar } = useSnackbar();



  const [dataFiltered, setdataFiltered] = useState<any[]>([]);
  const [totaldados, settotal] = useState<any>({});
  const [gettotalinvestimento, settotalinvestimento] = useState<any>('');
  const [getdoacao, setdoacao] = useState<any>({});
  const [getreembolso, setreembolso] = useState<any>({});
  const [totaltaxadados, settotaltaxa] = useState<any>({});
  const [totalfinaceiro, settotalfinaceiro] = useState(0);
  const [saldocerto, setsaldo] = useState(0);



  const getfinaceiro= useCallback(async () => {
    try {
      const response = await axios.post(`/areadoheroi/financeiro/all?page=${page+1}`,{
        filterStartDate,
        filterEndDate,
        filterService,
        filterStatus,
        rowsPerPage,
        filterName,
        order,
        orderBy
      });

      const {financeiro,saldo,totalinvestimento,doacao,reembolso,totaltaxa} = response.data;

      setdataFiltered(financeiro.data);
      settotalfinaceiro(financeiro.total);
      setsaldo(saldo);
      settotalinvestimento(totalinvestimento);
      setdoacao(doacao);
      setreembolso(reembolso);
      settotaltaxa(totaltaxa);

    } catch (error) {
      console.error(error);
    }
  },[page,setsaldo,setdataFiltered,filterStartDate,filterEndDate,filterService,filterStatus,filterName,rowsPerPage,settotalfinaceiro,order,orderBy,settotalinvestimento,setdoacao,setreembolso,settotaltaxa]);



  useEffect(() => {

    getfinaceiro();

  }, [getfinaceiro]);

  const { user,getuser } = useAuthContext();


  useEffect(() => {

    getuser();

  }, [getuser]);



  const dataInPage = dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);



  const denseHeight = dense ? 56 : 76;

  const isFiltered =
    filterStatus !== 'all' ||
    filterName !== '' ||
    filterService !== 'all' ||
    (!!filterStartDate && !!filterEndDate);

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus) ||
    (!dataFiltered.length && !!filterService) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  const getLengthByStatus = (status: string) =>
    tableData.filter((item) => item.status === status).length;

  const getTotalPriceByStatus = (status: string) =>
    sumBy(
      tableData.filter((item) => item.status === status),
      'totalPrice'
    );


  const getPercentByStatus = (status: string) =>
    (getLengthByStatus(status) / tableData.length) * 100;

  const TABS = [
    { value: 'todos', label: 'Todos', color: 'info', count: tableData.length },
    { value: 'confirmado', label: 'Pagos', color: 'success', count: getLengthByStatus('confirmado') },
    { value: 'pendente', label: 'Não Pagos', color: 'error', count: getLengthByStatus('pendente') },
    { value: 'não confirmado', label: 'Atrasados', color: 'warning', count: getLengthByStatus('não confirmado') },
    { value: 'aguardando', label: 'Rascunhos', color: 'default', count: getLengthByStatus('aguardando') },
  ] as const;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterStatus = (event: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const handleDeleteRow = (id: string) => {
    const deleteRow = tableData.filter((row) => row.id !== id);
    setSelected([]);
    setTableData(deleteRow);

    if (page > 0) {
      if (dataInPage.length < 2) {
        setPage(page - 1);
      }
    }
  };

  const handleDeleteRows = (selectedRows: string[]) => {
    const deleteRows = tableData.filter((row) => !selectedRows.includes(row.id));
    setSelected([]);
    setTableData(deleteRows);

    if (page > 0) {
      if (selectedRows.length === dataInPage.length) {
        setPage(page - 1);
      } else if (selectedRows.length === dataFiltered.length) {
        setPage(0);
      } else if (selectedRows.length > dataInPage.length) {
        const newPage = Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) - 1;
        setPage(newPage);
      }
    }
  };

  const handleEditRow = (id: string) => {
    /* navigate(PATH_DASHBOARD.invoice.edit(id)); */
  };

  const handleViewRow = (id: string) => {
    /* navigate(PATH_DASHBOARD.invoice.view(id)); */
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterStatus('all');
    setFilterService('all');
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const [chave, setChavePix] = useState<any>('');
  const [motivo, setMotivo] = useState<any>('');
  const [tipoChave, settipoChave] = useState<any>('');
  const [valor, setvalor] = useState<any>();
  const [senha, setSenha] = useState<any>();
  const [botaodesabilitador, setbotaodesabilitador] = useState(false);


  const reembosso= useCallback(async () => {
    setbotaodesabilitador(true)
    try {
      const response = await axios.post(`/areadoheroi/pedirreembolso`, {
        valor,
        chave,
        senha,
        tipoChave,
        motivo
      });

      enqueueSnackbar(`${response.data.message}`, {variant: 'success'});
      getfinaceiro();
      setvalor('');
      setChavePix('');
      setSenha('');
      setMotivo('');
      settipoChave('');
      setbotaodesabilitador(false);
      setOpen(false);

    } catch (error) {
      enqueueSnackbar(`${error.message}`, {variant: 'error'});
      setbotaodesabilitador(false);
    }
  }, [valor, chave, senha, tipoChave, motivo, enqueueSnackbar, getfinaceiro]);


  const handlevalor = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setvalor(value);

  };


  const handleChangeTipoPix = (event: SelectChangeEvent) => {
    settipoChave(event.target.value );
  };

  const handlechave= async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setChavePix(value);

  };
  const handlemotivo= async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setMotivo(value);

  };
  const handlesenha = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSenha(value);

  };
  
  
  
  const modal  = (


      <Grid container>



        <Grid item xs={12} md={12}>

          <Item>

            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
              <InputLabel htmlFor="filled-adornment-amount">Valor:</InputLabel>
              <FilledInput
                  id="filled-adornment-amount"
                  onChange={handlevalor}
                  startAdornment={<InputAdornment position="start">R$</InputAdornment>}
              />
            </FormControl>

            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
              <InputLabel id="demo-simple-select-label">Tipo de Chave Pix</InputLabel>
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={tipoChave}
                  label="Chave Pix"
                  onChange={handleChangeTipoPix}
              >
                <MenuItem value='CPF'>CPF</MenuItem>
                <MenuItem value='CNPJ'>CNPJ</MenuItem>
                <MenuItem value='E-mail'>E-mail</MenuItem>
                <MenuItem value='Celular'>Celular</MenuItem>
                <MenuItem value='Chave'>Chave Aleatória</MenuItem>
              </Select>
            </FormControl>


          </Item>
        </Grid>

        <Grid item xs={12} md={12}>
          <Item>

            <FormControl fullWidth sx={{ m: 1 }} variant="filled">
              <InputLabel htmlFor="filled-adornment-amount">Chave Pix:</InputLabel>
              <FilledInput
                  onChange={handlechave}
                  id="filled-adornment-amount"
              />
            </FormControl>




          </Item>
        </Grid>
        <Grid item xs={12} md={12}>
          <Item>

            <FormControl fullWidth sx={{ m: 1 }} variant="filled">

              <TextField
                  id="outlined-multiline-flexible"
                  label="Motivo"
                  multiline
                  onChange={handlemotivo}
                  maxRows={4}
              />

            </FormControl>


          </Item>
        </Grid>

        <Typography variant='subtitle1' sx={{mt: 2,textAlign:'center', fontSize:'15px', color:'red'}} >
          <br/>Atenção: O valor reembolsado estará disponível em sua conta bancária em até 5 dias utéis. <br/>
        </Typography>

        <Grid item xs={12} md={12}>
          <Item><Button variant='contained' color="success" sx={{mb: 2}} onClick={reembosso} disabled={botaodesabilitador} className='BtnSolicitarSaqueFinanceiroPage'>Solicitar Reembolso</Button></Item>
        </Grid>



      </Grid>

  );

  return (
    <>
      

      <Box sx={{ p: 2.5 }}>
        <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Dashboard </Button>
                
        <CustomBreadcrumbs
          heading="Financeiro Herói"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root, 
            },
            {
              name: 'Financeiro',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Lista',
            },
          ]}
          action={
            <Button
              onClick={handleClickOpen}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
             Pedir Reembolso
            </Button>
          }
        />
        </Box>

        <Card sx={{ mb: 5 }}>
          <Scrollbar>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
              sx={{ py: 2 }}
            >
              <InvoiceAnalytic
                title="Saldo"
                total=''
                percent={100}
                price={saldocerto}
                dadoComplementar="Disponível"
                icon="ic:round-receipt"
                color={theme.palette.info.main}
              />

              <InvoiceAnalytic
                title="Total"
                total=''
                percent={100}
                price={gettotalinvestimento}
                dadoComplementar="Investido em saúde"
                icon="eva:checkmark-circle-2-fill"
                color={theme.palette.success.main}
              />

              <InvoiceAnalytic
                title="Reembolsos"
                total=''
                percent={100}
                price={getreembolso}
                dadoComplementar="Total reembolsado"
                icon="eva:clock-fill"
                color={theme.palette.error.main}
              />

              <InvoiceAnalytic
                title="Contribuições"
                total=''
                percent={100}
                price={getdoacao}
                dadoComplementar="Efetuadas"
                icon="eva:bell-fill"
                color={theme.palette.warning.main}
              />

              
            </Stack>
          </Scrollbar>
        </Card>

        <Card>
          

          <Divider />

          <InvoiceTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterService={filterService}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            optionsService={SERVICE_OPTIONS}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  // onSelectAllRows={(checked) =>
                  //   onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  
                  {  dataFiltered
                  //  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <InvoiceTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      />
                    ))  }

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, totalfinaceiro)}
                  />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalfinaceiro}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      
      

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Delete"
        content={
          <>
            Tem certeza de que deseja excluir <strong> {selected.length} </strong> dados?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            Delete
          </Button>
        }
      />


      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Solicitar Reembolso</DialogTitle>
        <DialogContent>
          {modal}
        </DialogContent>

      </Dialog>
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterService,
  filterStartDate,
  filterEndDate,
}: {
  inputData: IInvoice[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterService: string;
  filterStartDate: Date | null;
  filterEndDate: Date | null;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (invoice) =>
        invoice.invoiceNumber.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        invoice.invoiceTo.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  if (filterStatus !== 'all') {
    inputData = inputData.filter((invoice) => invoice.status === filterStatus);
  }

  if (filterService !== 'all') {
    inputData = inputData.filter((invoice) =>
      invoice.items.some((c) => c.service === filterService)
    );
  }

  if (filterStartDate && filterEndDate) {
    inputData = inputData.filter(
      (invoice) =>
        fTimestamp(invoice.createDate) >= fTimestamp(filterStartDate) &&
        fTimestamp(invoice.createDate) <= fTimestamp(filterEndDate)
    );
  }

  return inputData;
}
